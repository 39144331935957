import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Layout from '../../../components/Layout';
import Seo from '../../../components/Seo';
import WistiaPopoverVideo from '../../../components/WistiaPopoverVideo';
import FreeTrialSignup from '../../../components/FreeTrialSignup';

import './style.scss';

const Page = () => {
    return (
        <Layout navTheme="light">
            <Seo
                title="New release updates | GlassHive"
                description="At GlassHive, we are continuously making improvements to the platform to enhance user experience and functionality. Check out the newest features!"
            />
            <div className="release-notes-page">
                <Container className="container-1">
                    <Row className="center">
                        <Col className="centered-text">
                            <h1 className="white-text">
                                GlassHive new release updates
                            </h1>
                            <p className="hero-subtext white-text">
                                Check out what’s been going on
                            </p>
                        </Col>
                    </Row>
                    <img
                        className="white-divider"
                        src={require('../../../images/features/lead-scrubbing/white-divider.svg')}
                        alt="white divider"
                    />
                </Container>
                <Container className="container-2">
                    <Row>
                        <Col className="three-fifth video-column">
                            <WistiaPopoverVideo id={'yxjwwk7jvm'}>
                                <img
                                    className="thumbnail"
                                    src="https://embed-fastly.wistia.com/deliveries/61ed4c6571d69d086555ba841f7cf99f.jpg?image_play_button_size=2x&amp;image_crop_resized=960x540&amp;image_play_button=1&amp;image_play_button_color=ef6cc4e0"
                                    alt="Release notes video thumbnail"
                                />
                            </WistiaPopoverVideo>
                        </Col>
                        <Col className="two-fifth">
                            <h4 className="centered-mobile">
                                The latest features with GlassHive
                            </h4>
                            <p>
                                At GlassHive, we are continuously making
                                improvements to the platform to enhance user
                                experience and functionality.
                            </p>
                            <p>
                                With all these updates, we want to make sure
                                you’re in the loop too!
                            </p>
                            <p>
                                Check out the newest updates and features we’ve
                                released to take full advantage of GlassHive and
                                take your sales and marketing to the next level.
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Container className="container-3">
                    <Row>
                        <Col className="two-fifth">
                            <h4 className="centered-mobile">New features</h4>
                            <ul>
                                <li>
                                    Added new User Guide to help users get to
                                    know GlassHive
                                </li>
                                <li>
                                    Created a new pop up menu when you click on
                                    your profile image
                                </li>
                                <li>
                                    Added ability to edit CC for draft
                                    one-to-one emails
                                </li>
                                <li>
                                    Added additional columns to Sent Campaigns
                                    table
                                </li>
                                <li>Added the ability to view all campaigns</li>
                                <li>
                                    Added prefilled text when creating any
                                    graphical email from scratch
                                </li>
                            </ul>

                            <h4 className="centered-mobile">Updates</h4>
                            <ul>
                                <li>Tables now have updated header UI</li>
                                <li>
                                    Updated tables to have better distinction
                                    between cells
                                </li>
                                <li>
                                    Updated Marketing Plan tables to have table
                                    and card view
                                </li>
                                <li>
                                    Updated cell consistency across various
                                    tables
                                </li>
                                <li>Updated UI on Profile Settings</li>
                                <li>Updated UI on Email Confirmation page</li>
                                <li>Updated UI on Account Settings page</li>
                                <li>Updated Password Reset page UI</li>
                                <li>
                                    Users can now go back when adding a contact
                                </li>
                                <li>
                                    Users can now create campaigns from the
                                    Scheduled Campaigns page
                                </li>
                                <li>
                                    Updated CC section to pull all users instead
                                    of only salespeople
                                </li>
                            </ul>

                            <h4 className="centered-mobile">Bug fixes</h4>
                            <ul>
                                <li>
                                    Fixed fonts not rendering correctly in
                                    Collateral Builder and Campaign Builder
                                </li>
                                <li>
                                    Fixed bug when adding a new contact with an
                                    email with special characters
                                </li>
                                <li>
                                    Fixed bug on Mac where pressing command
                                    would perform unintended actions
                                </li>
                                <li>
                                    Fixed bug on tables where hovering would
                                    cause content to jump
                                </li>
                                <li>Fixed bug when updating password</li>
                                <li>
                                    Fixed bug where video thumbnail would get
                                    generated multiple times in Campaign Builder
                                </li>
                            </ul>
                        </Col>
                        <Col className="three-fifth form-column">
                            <FreeTrialSignup titleText="Sign up for a free account today!" />
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    );
};

export default Page;
